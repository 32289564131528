import {
  ApiKey,
  ApiKeyPartial,
  ApiKeyRequired,
  ApiKeysService,
  AzureDevopsAuthorization,
  AzureDevopsAuthorizationPartial,
  AzureDevopsService,
  BitbucketAuthorization,
  BitbucketAuthorizationPartial,
  BitbucketServerService,
  BitbucketServerToken,
  BitbucketServerTokenPartial,
  BitbucketServerTokenRequired,
  BitbucketService,
  CancelablePromise,
  GithubInstall,
  GithubService,
  GitlabAuthorization,
  GitlabAuthorizationPartial,
  GitlabService,
  Organization,
  OrganizationBillingService,
  OrganizationBillingSubscriptionCreate,
  OrganizationBillingSubscriptionUpdate,
  OrganizationBrand,
  OrganizationPartial,
  OrganizationRequired,
  OrganizationsService,
  OrganizationUser,
  OrganizationUserInfo,
  OrganizationUserInvite,
  OrganizationUserInviteRequired,
  OrganizationUserInvitesService,
  OrganizationUsersService
} from '@icepanel/platform-api-client'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import Status from '@/helpers/status'
import userAnalyticsProfile from '@/modules/user/helpers/analytics-profile'

import organizationAnalyticsGroup from './helpers/analytics-group'
import limits from './helpers/limits'

export interface IOrganizationModule {
  organizations: Organization[]
  organizationUsers: Record<string, OrganizationUser & OrganizationUserInfo>
  organizationBrands: Record<string, OrganizationBrand>
  organizationUserInvites: OrganizationUserInvite[]

  apiKeys: ApiKey[]

  azureDevopsAuthorizations: AzureDevopsAuthorization[]
  bitbucketAuthorizations: BitbucketAuthorization[]
  bitbucketServerTokens: BitbucketServerToken[]
  githubInstalls: GithubInstall[]
  gitlabAuthorizations: GitlabAuthorization[]

  organizationsListStatus: Status
  organizationCreateStatus: Status
  organizationUsersListStatus: Status<{ promise: CancelablePromise<any>, organizationId: string }, { organizationId: string }>
  organizationUserInvitesListStatus: Status<{ promise: CancelablePromise<any>, organizationId: string }, { organizationId: string }>
}

const name = 'organization'

@Module({
  name,
  namespaced: true
})
export class OrganizationModule extends VuexModule {
  static namespace = name

  organizations: Organization[] = []
  organizationUsers: Record<string, Record<string, OrganizationUser & OrganizationUserInfo>> = {}
  organizationBrands: Record<string, OrganizationBrand> = {}
  organizationUserInvites: OrganizationUserInvite[] = []

  apiKeys: ApiKey[] = []

  azureDevopsAuthorizations: AzureDevopsAuthorization[] = []
  bitbucketAuthorizations: BitbucketAuthorization[] = []
  bitbucketServerTokens: BitbucketServerToken[] = []
  githubInstalls: GithubInstall[] = []
  gitlabAuthorizations: GitlabAuthorization[] = []

  organizationsListStatus = new Status()
  organizationCreateStatus = new Status()
  organizationUsersListStatus = new Status<{ promise: CancelablePromise<any>, organizationId: string }, { organizationId: string }>()
  organizationUserInvitesListStatus = new Status<{ promise: CancelablePromise<any>, organizationId: string }, { organizationId: string }>()

  get userOrganizations () {
    const userId = this.context.rootState.user.user?.id
    return this.organizations.filter(o => o.userIds.includes(userId))
  }

  get organizationPermission () {
    return (organization?: Organization | null) => {
      const userId = this.context.rootState.user.user?.id
      const organizationUser = organization?.users[userId]
      if (organizationUser) {
        return organizationUser.permission
      } else {
        return null
      }
    }
  }

  get organizationLimits () {
    return (organization?: Organization | null) => limits(organization)
  }

  @Mutation
  setOrganizations (organizations: Organization[]) {
    this.organizations = [
      ...this.organizations,
      ...organizations
    ]
  }

  @Mutation
  setOrganization (organization: Organization) {
    this.organizations = [
      ...this.organizations.filter(o => o.id !== organization.id),
      organization
    ]
  }

  @Mutation
  resetOrganizations () {
    this.organizations = []
  }

  @Mutation
  removeOrganization (organizationId: string) {
    this.organizations = this.organizations.filter(o => o.id !== organizationId)
  }

  @Mutation
  setOrganizationUser ({ organizationId, userId, organizationUser }: { organizationId: string, userId: string, organizationUser: OrganizationUser }) {
    this.organizationUsers = {
      ...this.organizationUsers || {},
      [organizationId]: {
        ...this.organizationUsers[organizationId] || {},
        [userId]: {
          ...this.organizationUsers[organizationId]?.[userId] || {},
          landscapePermissions: undefined,
          ...organizationUser
        }
      }
    }
  }

  @Mutation
  setOrganizationUserInfo ({ organizationId, userId, organizationUser }: { organizationId: string, userId: string, organizationUser: OrganizationUser & OrganizationUserInfo }) {
    this.organizationUsers = {
      ...this.organizationUsers || {},
      [organizationId]: {
        ...this.organizationUsers[organizationId] || {},
        [userId]: {
          ...this.organizationUsers[organizationId]?.[userId] || {},
          ...organizationUser
        }
      }
    }
  }

  @Mutation
  removeOrganizationUser ({ organizationId, userId }: { organizationId: string, userId: string }) {
    Vue.delete(this.organizationUsers[organizationId], userId)
  }

  @Mutation
  setOrganizationBrand ({ organizationId, organizationBrand }: { organizationId: string, organizationBrand: OrganizationBrand }) {
    Vue.set(this.organizationBrands, organizationId, organizationBrand)
  }

  @Mutation
  removeOrganizationBrand (organizationId: string) {
    Vue.delete(this.organizationBrands, organizationId)
  }

  @Mutation
  setOrganizationUserInvites (organizationUserInvites: OrganizationUserInvite[]) {
    this.organizationUserInvites = organizationUserInvites
  }

  @Mutation
  setOrganizationUserInvite (organizationUserInvite: OrganizationUserInvite) {
    this.organizationUserInvites = [
      ...this.organizationUserInvites.filter(o => o.id !== organizationUserInvite.id),
      organizationUserInvite
    ]
  }

  @Mutation
  resetOrganizationUserInvites () {
    this.organizationUserInvites = []
  }

  @Mutation
  removeOrganizationUserInvite (organizationUserInviteId: string) {
    this.organizationUserInvites = this.organizationUserInvites.filter(o => o.id !== organizationUserInviteId)
  }

  @Mutation
  setApiKeys (apiKeys: ApiKey[]) {
    this.apiKeys = apiKeys
    this.apiKeys.sort((a, b) => a.name.localeCompare(b.name))
  }

  @Mutation
  setApiKey (apiKey: ApiKey) {
    this.apiKeys = [
      ...this.apiKeys.filter(o => o.id !== apiKey.id),
      apiKey
    ]
    this.apiKeys.sort((a, b) => a.name.localeCompare(b.name))
  }

  @Mutation
  removeApiKey (apiKeyId: string) {
    this.apiKeys = this.apiKeys.filter(o => o.id !== apiKeyId)
  }

  @Mutation
  setAzureDevopsAuthorizations (azureDevopsAuthorizations: AzureDevopsAuthorization[]) {
    this.azureDevopsAuthorizations = azureDevopsAuthorizations
  }

  @Mutation
  setBitbucketAuthorizations (bitbucketAuthorizations: BitbucketAuthorization[]) {
    this.bitbucketAuthorizations = bitbucketAuthorizations
  }

  @Mutation
  setBitbucketServerTokens (bitbucketServerTokens: BitbucketServerToken[]) {
    this.bitbucketServerTokens = bitbucketServerTokens
  }

  @Mutation
  setGithubInstalls (githubInstalls: GithubInstall[]) {
    this.githubInstalls = githubInstalls
  }

  @Mutation
  setGitlabAuthorizations (gitlabAuthorizations: GitlabAuthorization[]) {
    this.gitlabAuthorizations = gitlabAuthorizations
  }

  @Mutation
  setOrganizationsListStatus (...params: Parameters<typeof this.organizationsListStatus.set>) {
    this.organizationsListStatus.set(...params)
  }

  @Mutation
  setOrganizationCreateStatus (...params: Parameters<typeof this.organizationCreateStatus.set>) {
    this.organizationCreateStatus.set(...params)
  }

  @Mutation
  setOrganizationUsersListStatus (...params: Parameters<typeof this.organizationUsersListStatus.set>) {
    this.organizationUsersListStatus.loadingInfo.promise?.cancel()
    this.organizationUsersListStatus.set(...params)
  }

  @Mutation
  setOrganizationUserInvitesStatus (...params: Parameters<typeof this.organizationUserInvitesListStatus.set>) {
    this.organizationUserInvitesListStatus.loadingInfo.promise?.cancel()
    this.organizationUserInvitesListStatus.set(...params)
  }

  @Action({ rawError: true })
  async organizationFind (id: string) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { organization } = await OrganizationsService.organizationFind(authorization, id)
    this.context.commit('setOrganization', organization)

    organizationAnalyticsGroup.set(organization.id, {
      appUrl: `${env.APP_URL}/organizations/${organization.id}`,
      autoInviteDomains: organization.autoInviteDomains,
      billingCurrency: organization.billingCurrency,
      billingCycle: organization.billingCycle,
      billingPaymentMethod: organization.billingPaymentMethod,
      cancelAt: organization.cancelAt,
      canceledAt: organization.canceledAt,
      createdAt: organization.createdAt,
      email: organization.billingEmail,
      name: organization.name,
      plan: organization.plan,
      planEndsAt: organization.planEndsAt,
      seats: organization.seats,
      shareLinkAuthDomains: organization.shareLinkAuthDomains,
      shareLinksEnabled: organization.shareLinksEnabled,
      status: organization.status,
      trialEndsAt: organization.trialEndsAt,
      userAdminCount: Object.values(organization.users).filter(o => o.permission === 'admin').length,
      userCount: organization.userIds.length,
      userId: organization.userIds,
      userReadCount: Object.values(organization.users).filter(o => o.permission === 'read').length,
      userWriteCount: Object.values(organization.users).filter(o => o.permission === 'write').length
    })

    return organization
  }

  @Action({ rawError: true })
  async organizationsList () {
    try {
      this.context.commit('setOrganizationsListStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { organizations } = await OrganizationsService.organizationsList(authorization)

      this.context.commit('resetOrganizations')
      this.context.commit('setOrganizations', organizations)

      organizations.forEach(o => {
        organizationAnalyticsGroup.set(o.id, {
          appUrl: `${env.APP_URL}/organizations/${o.id}`,
          autoInviteDomains: o.autoInviteDomains,
          billingCurrency: o.billingCurrency,
          billingCycle: o.billingCycle,
          billingPaymentMethod: o.billingPaymentMethod,
          cancelAt: o.cancelAt,
          canceledAt: o.canceledAt,
          createdAt: o.createdAt,
          email: o.billingEmail,
          name: o.name,
          plan: o.plan,
          planEndsAt: o.planEndsAt,
          seats: o.seats,
          shareLinkAuthDomains: o.shareLinkAuthDomains,
          shareLinksEnabled: o.shareLinksEnabled,
          status: o.status,
          trialEndsAt: o.trialEndsAt,
          userAdminCount: Object.values(o.users).filter(o => o.permission === 'admin').length,
          userCount: o.userIds.length,
          userId: o.userIds,
          userReadCount: Object.values(o.users).filter(o => o.permission === 'read').length,
          userWriteCount: Object.values(o.users).filter(o => o.permission === 'write').length
        })
      })

      userAnalyticsProfile.set({
        organizationCount: this.userOrganizations.length,
        organizationId: this.userOrganizations.map(o => o.id)
      })

      this.context.commit('setOrganizationsListStatus', Status.success())

      return organizations
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setOrganizationsListStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async organizationCreate (props: OrganizationRequired) {
    try {
      this.context.commit('setOrganizationCreateStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { organization } = await OrganizationsService.organizationCreate(authorization, props)
      this.context.commit('setOrganization', organization)

      organizationAnalyticsGroup.set(organization.id, {
        appUrl: `${env.APP_URL}/organizations/${organization.id}`,
        autoInviteDomains: organization.autoInviteDomains,
        billingCurrency: organization.billingCurrency,
        billingCycle: organization.billingCycle,
        billingPaymentMethod: organization.billingPaymentMethod,
        cancelAt: organization.cancelAt,
        canceledAt: organization.canceledAt,
        createdAt: organization.createdAt,
        email: organization.billingEmail,
        landscapeCount: 0,
        landscapeId: [],
        name: organization.name,
        plan: organization.plan,
        planEndsAt: organization.planEndsAt,
        seats: organization.seats,
        shareLinkAuthDomains: organization.shareLinkAuthDomains,
        shareLinksEnabled: organization.shareLinksEnabled,
        status: organization.status,
        trialEndsAt: organization.trialEndsAt,
        userAdminCount: Object.values(organization.users).filter(o => o.permission === 'admin').length,
        userCount: organization.userIds.length,
        userId: organization.userIds,
        userReadCount: Object.values(organization.users).filter(o => o.permission === 'read').length,
        userWriteCount: Object.values(organization.users).filter(o => o.permission === 'write').length
      })

      userAnalyticsProfile.union('organizationId', [organization.id])
      userAnalyticsProfile.increment('organizationId', 1)

      this.context.commit('setOrganizationCreateStatus', Status.success())

      return organization
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setOrganizationCreateStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async updateOrganization ({ organizationId, props }: { organizationId: string, props: OrganizationPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { organization } = await OrganizationsService.organizationUpdate(authorization, organizationId, props)
    this.context.commit('setOrganization', organization)

    organizationAnalyticsGroup.set(organization.id, {
      autoInviteDomains: organization.autoInviteDomains,
      billingCurrency: organization.billingCurrency,
      billingCycle: organization.billingCycle,
      billingPaymentMethod: organization.billingPaymentMethod,
      cancelAt: organization.cancelAt,
      canceledAt: organization.canceledAt,
      createdAt: organization.createdAt,
      email: organization.billingEmail,
      name: organization.name,
      plan: organization.plan,
      planEndsAt: organization.planEndsAt,
      seats: organization.seats,
      shareLinkAuthDomains: organization.shareLinkAuthDomains,
      shareLinksEnabled: organization.shareLinksEnabled,
      status: organization.status,
      trialEndsAt: organization.trialEndsAt,
      userAdminCount: Object.values(organization.users).filter(o => o.permission === 'admin').length,
      userCount: organization.userIds.length,
      userId: organization.userIds,
      userReadCount: Object.values(organization.users).filter(o => o.permission === 'read').length,
      userWriteCount: Object.values(organization.users).filter(o => o.permission === 'write').length
    })

    return organization
  }

  @Action({ rawError: true })
  async organizationDelete (organizationId: string) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await OrganizationsService.organizationDelete(authorization, organizationId)

    userAnalyticsProfile.remove('organizationId', organizationId)
    userAnalyticsProfile.increment('organizationCount', -1)
  }

  @Action({ rawError: true })
  async organizationBillingLinkCreate ({ returnUrl, organizationId }: { returnUrl: string, organizationId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const links = await OrganizationBillingService.organizationBillingLinkCreate(authorization, organizationId, {
      returnUrl
    })
    return links
  }

  @Action({ rawError: true })
  async organizationBillingSubscriptionLinkCreate ({ organizationId, subscription, successUrl, cancelUrl }: { organizationId: string, subscription: OrganizationBillingSubscriptionCreate, successUrl: string, cancelUrl: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { url } = await OrganizationBillingService.organizationBillingSubscriptionLinkCreate(authorization, organizationId, {
      cancelUrl,
      subscription,
      successUrl
    })
    return url
  }

  @Action({ rawError: true })
  async organizationBillingSubscriptionCreate ({ organizationId, subscription }: { organizationId: string, subscription: OrganizationBillingSubscriptionCreate }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { organization } = await OrganizationBillingService.organizationBillingSubscriptionCreate(authorization, organizationId, {
      subscription
    })
    this.context.commit('setOrganization', organization)

    organizationAnalyticsGroup.set(organization.id, {
      autoInviteDomains: organization.autoInviteDomains,
      billingCurrency: organization.billingCurrency,
      billingCycle: organization.billingCycle,
      billingPaymentMethod: organization.billingPaymentMethod,
      cancelAt: organization.cancelAt,
      canceledAt: organization.canceledAt,
      createdAt: organization.createdAt,
      email: organization.billingEmail,
      name: organization.name,
      plan: organization.plan,
      planEndsAt: organization.planEndsAt,
      seats: organization.seats,
      shareLinkAuthDomains: organization.shareLinkAuthDomains,
      shareLinksEnabled: organization.shareLinksEnabled,
      status: organization.status,
      trialEndsAt: organization.trialEndsAt,
      userAdminCount: Object.values(organization.users).filter(o => o.permission === 'admin').length,
      userCount: organization.userIds.length,
      userId: organization.userIds,
      userReadCount: Object.values(organization.users).filter(o => o.permission === 'read').length,
      userWriteCount: Object.values(organization.users).filter(o => o.permission === 'write').length
    })

    return organization
  }

  @Action({ rawError: true })
  async organizationBillingSubscriptionUpdate ({ organizationId, update }: { organizationId: string, update: OrganizationBillingSubscriptionUpdate }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { organization } = await OrganizationBillingService.organizationBillingSubscriptionUpdate(authorization, organizationId, update)
    this.context.commit('setOrganization', organization)

    organizationAnalyticsGroup.set(organization.id, {
      autoInviteDomains: organization.autoInviteDomains,
      billingCurrency: organization.billingCurrency,
      billingCycle: organization.billingCycle,
      billingPaymentMethod: organization.billingPaymentMethod,
      cancelAt: organization.cancelAt,
      canceledAt: organization.canceledAt,
      createdAt: organization.createdAt,
      email: organization.billingEmail,
      name: organization.name,
      plan: organization.plan,
      planEndsAt: organization.planEndsAt,
      seats: organization.seats,
      shareLinkAuthDomains: organization.shareLinkAuthDomains,
      shareLinksEnabled: organization.shareLinksEnabled,
      status: organization.status,
      trialEndsAt: organization.trialEndsAt,
      userAdminCount: Object.values(organization.users).filter(o => o.permission === 'admin').length,
      userCount: organization.userIds.length,
      userId: organization.userIds,
      userReadCount: Object.values(organization.users).filter(o => o.permission === 'read').length,
      userWriteCount: Object.values(organization.users).filter(o => o.permission === 'write').length
    })

    return organization
  }

  @Action({ rawError: true })
  async organizationBillingSubscriptionCancel (organizationId: string) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await OrganizationBillingService.organizationBillingSubscriptionCancel(authorization, organizationId)
  }

  @Action({ rawError: true })
  async organizationUsersList (organizationId: string) {
    try {
      this.context.commit('setOrganizationUsersListStatus', Status.loading({
        organizationId
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const promise = OrganizationUsersService.organizationUsersList(authorization, organizationId)

      this.context.commit('setOrganizationUsersListStatus', Status.loading({
        organizationId,
        promise
      }))

      const { organizationUsers } = await promise

      Object.entries(organizationUsers).forEach(([userId, organizationUser]) => {
        this.context.commit('setOrganizationUserInfo', {
          organizationId,
          organizationUser,
          userId
        })
      })

      this.context.commit('setOrganizationUsersListStatus', Status.success({
        organizationId
      }))

      return organizationUsers
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setOrganizationUsersListStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async organizationUserFind ({ organizationId, userId, email }: { organizationId: string, userId?: string, email?: string }) {
    try {
      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { organizationUsers } = await OrganizationUsersService.organizationUsersList(authorization, organizationId)

      let organizationUserId: string | undefined
      let organizationUser: OrganizationUser & OrganizationUserInfo | undefined
      if (userId) {
        organizationUserId = userId
        organizationUser = organizationUsers[userId]
      } else if (email) {
        const user = Object.entries(organizationUsers).find(o => o[1].email === email)
        if (user) {
          organizationUserId = user[0]
          organizationUser = user[1]
        }
      }

      if (organizationUser) {
        this.context.commit('setOrganizationUserInfo', {
          organizationId,
          organizationUser,
          userId: organizationUserId
        })
      }

      return organizationUser
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async organizationUserUpdate ({ organizationId, userId, body }: { organizationId: string, userId: string, body: OrganizationUser }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { organizationUser } = await OrganizationUsersService.organizationUserUpdate(authorization, organizationId, userId, body)
    this.context.commit('setOrganizationUser', {
      organizationId,
      organizationUser,
      userId
    })
    return {
      organizationUser
    }
  }

  @Action({ rawError: true })
  async organizationUserDelete ({ organizationId, userId }: { organizationId: string, userId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await OrganizationUsersService.organizationUserDelete(authorization, organizationId, userId)
    this.context.commit('removeOrganizationUser', {
      organizationId,
      userId
    })
  }

  @Action({ rawError: true })
  async organizationUserInvitesList (organizationId: string) {
    try {
      this.context.commit('setOrganizationUserInvitesStatus', Status.loading({
        organizationId
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const promise = OrganizationUserInvitesService.organizationUserInvitesList(authorization, organizationId)

      this.context.commit('setOrganizationUserInvitesStatus', Status.loading({
        organizationId,
        promise
      }))

      const { organizationUserInvites } = await promise

      this.context.commit('setOrganizationUserInvites', organizationUserInvites)

      this.context.commit('setOrganizationUserInvitesStatus', Status.success({
        organizationId
      }))

      return organizationUserInvites
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setOrganizationUserInvitesStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async organizationUserInviteCreate ({ organizationId, invite }: { organizationId: string, invite: OrganizationUserInviteRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { organizationUserInvite } = await OrganizationUserInvitesService.organizationUserInviteCreate(authorization, organizationId, invite)
    this.context.commit('setOrganizationUserInvite', organizationUserInvite)

    if (organizationUserInvite.usedAt) {
      await this.context.dispatch('organizationUserFind', {
        email: organizationUserInvite.email,
        organizationId
      })
    }

    return organizationUserInvite
  }

  @Action({ rawError: true })
  async organizationUserInviteRevoke ({ organizationId, organizationUserInviteId }: { organizationId: string, organizationUserInviteId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { organizationUserInvite } = await OrganizationUserInvitesService.organizationUserInviteRevoke(authorization, organizationId, organizationUserInviteId)
    this.context.commit('setOrganizationUserInvite', organizationUserInvite)
    return organizationUserInvite
  }

  @Action({ rawError: true })
  async apiKeysList (organizationId: string) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { apiKeys } = await ApiKeysService.apiKeysList(authorization, organizationId)
    this.context.commit('setApiKeys', apiKeys)
    return apiKeys
  }

  @Action({ rawError: true })
  async apiKeyCreate ({ organizationId, create }: { organizationId: string, create: ApiKeyRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { apiKey, secret } = await ApiKeysService.apiKeyCreate(authorization, organizationId, create)
    this.context.commit('setApiKey', apiKey)
    return {
      apiKey,
      secret
    }
  }

  @Action({ rawError: true })
  async apiKeyUpdate ({ organizationId, apiKeyId, update }: { organizationId: string, apiKeyId: string, update: ApiKeyPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { apiKey } = await ApiKeysService.apiKeyUpdate(authorization, organizationId, apiKeyId, update)
    this.context.commit('setApiKey', apiKey)
    return apiKey
  }

  @Action({ rawError: true })
  async apiKeyDelete ({ organizationId, apiKeyId }: { organizationId: string, apiKeyId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await ApiKeysService.apiKeyDelete(authorization, organizationId, apiKeyId)
    this.context.commit('removeApiKey', apiKeyId)
  }

  @Action({ rawError: true })
  async azureDevopsAuthorizationsList (organizationId: string) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { azureDevopsAuthorizations } = await AzureDevopsService.azureDevopsAuthorizationsList(authorization, organizationId)
    this.context.commit('setAzureDevopsAuthorizations', azureDevopsAuthorizations)
    return azureDevopsAuthorizations
  }

  @Action({ rawError: true })
  async azureDevopsAuthorizationCreate ({ organizationId, code }: { organizationId: string, code: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { azureDevopsAuthorization } = await AzureDevopsService.azureDevopsAuthorizationCreate(authorization, organizationId, { code })
    this.context.commit('setAzureDevopsAuthorizations', [
      ...this.azureDevopsAuthorizations,
      azureDevopsAuthorization
    ])
    return azureDevopsAuthorization
  }

  @Action({ rawError: true })
  async azureDevopsAuthorizationAvailableAccounts ({ organizationId, azureDevopsAuthorizationId }: { organizationId: string, azureDevopsAuthorizationId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { accounts } = await AzureDevopsService.azureDevopsAuthorizationAvailableAccounts(authorization, organizationId, azureDevopsAuthorizationId)
    return accounts
  }

  @Action({ rawError: true })
  async azureDevopsAuthorizationUpdate ({ organizationId, azureDevopsAuthorizationId, update }: { organizationId: string, azureDevopsAuthorizationId: string, update: AzureDevopsAuthorizationPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { azureDevopsAuthorization } = await AzureDevopsService.azureDevopsAuthorizationUpdate(authorization, organizationId, azureDevopsAuthorizationId, update)
    this.context.commit('setAzureDevopsAuthorizations', this.azureDevopsAuthorizations.map(o => o.id === azureDevopsAuthorizationId ? azureDevopsAuthorization : o))
    return azureDevopsAuthorization
  }

  @Action({ rawError: true })
  async azureDevopsAuthorizationDelete ({ organizationId, azureDevopsAuthorizationId }: { organizationId: string, azureDevopsAuthorizationId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await AzureDevopsService.azureDevopsAuthorizationDelete(authorization, organizationId, azureDevopsAuthorizationId)
    this.context.commit('setAzureDevopsAuthorizations', this.azureDevopsAuthorizations.filter(o => o.id !== azureDevopsAuthorizationId))
  }

  @Action({ rawError: true })
  async bitbucketAuthorizationsList (organizationId: string) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { bitbucketAuthorizations } = await BitbucketService.bitbucketAuthorizationsList(authorization, organizationId)
    this.context.commit('setBitbucketAuthorizations', bitbucketAuthorizations)
    return bitbucketAuthorizations
  }

  @Action({ rawError: true })
  async bitbucketAuthorizationCreate ({ organizationId, code }: { organizationId: string, code: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { bitbucketAuthorization } = await BitbucketService.bitbucketAuthorizationCreate(authorization, organizationId, { code })
    this.context.commit('setBitbucketAuthorizations', [
      ...this.bitbucketAuthorizations,
      bitbucketAuthorization
    ])
    return bitbucketAuthorization
  }

  @Action({ rawError: true })
  async bitbucketAuthorizationAvailableWorkspaces ({ organizationId, bitbucketAuthorizationId }: { organizationId: string, bitbucketAuthorizationId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { workspaces } = await BitbucketService.bitbucketAuthorizationAvailableWorkspaces(authorization, organizationId, bitbucketAuthorizationId)
    return workspaces
  }

  @Action({ rawError: true })
  async bitbucketAuthorizationUpdate ({ organizationId, bitbucketAuthorizationId, update }: { organizationId: string, bitbucketAuthorizationId: string, update: BitbucketAuthorizationPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { bitbucketAuthorization } = await BitbucketService.bitbucketAuthorizationUpdate(authorization, organizationId, bitbucketAuthorizationId, update)
    this.context.commit('setBitbucketAuthorizations', this.bitbucketAuthorizations.map(o => o.id === bitbucketAuthorizationId ? bitbucketAuthorization : o))
    return bitbucketAuthorization
  }

  @Action({ rawError: true })
  async bitbucketAuthorizationDelete ({ organizationId, bitbucketAuthorizationId }: { organizationId: string, bitbucketAuthorizationId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await BitbucketService.bitbucketAuthorizationDelete(authorization, organizationId, bitbucketAuthorizationId)
    this.context.commit('setBitbucketAuthorizations', this.bitbucketAuthorizations.filter(o => o.id !== bitbucketAuthorizationId))
  }

  @Action({ rawError: true })
  async bitbucketServerTokensList (organizationId: string) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { bitbucketServerTokens } = await BitbucketServerService.bitbucketServerTokensList(authorization, organizationId)
    this.context.commit('setBitbucketServerTokens', bitbucketServerTokens)
    return bitbucketServerTokens
  }

  @Action({ rawError: true })
  async bitbucketServerTokenCreate ({ organizationId, create }: { organizationId: string, create: BitbucketServerTokenRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { bitbucketServerToken } = await BitbucketServerService.bitbucketServerTokenCreate(authorization, organizationId, create)
    this.context.commit('setBitbucketServerTokens', [
      ...this.bitbucketServerTokens,
      bitbucketServerToken
    ])
    return bitbucketServerToken
  }

  @Action({ rawError: true })
  async bitbucketServerTokenUpdate ({ organizationId, bitbucketServerTokenId, update }: { organizationId: string, bitbucketServerTokenId: string, update: BitbucketServerTokenPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { bitbucketServerToken } = await BitbucketServerService.bitbucketServerTokenUpdate(authorization, organizationId, bitbucketServerTokenId, update)
    this.context.commit('setBitbucketServerTokens', this.bitbucketServerTokens.map(o => o.id === bitbucketServerTokenId ? bitbucketServerToken : o))
    return bitbucketServerToken
  }

  @Action({ rawError: true })
  async bitbucketServerTokenDelete ({ organizationId, bitbucketServerTokenId }: { organizationId: string, bitbucketServerTokenId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await BitbucketServerService.bitbucketServerTokenDelete(authorization, organizationId, bitbucketServerTokenId)
    this.context.commit('setBitbucketServerTokens', this.bitbucketServerTokens.filter(o => o.id !== bitbucketServerTokenId))
  }

  @Action({ rawError: true })
  async githubInstallsList (organizationId: string) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { githubInstalls } = await GithubService.githubInstallsList(authorization, organizationId)
    this.context.commit('setGithubInstalls', githubInstalls)
    return githubInstalls
  }

  @Action({ rawError: true })
  async githubInstallCreate ({ organizationId, code, githubInstallId }: { organizationId: string, code: string, githubInstallId: number }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { githubInstall } = await GithubService.githubInstallCreate(authorization, organizationId, {
      code,
      githubInstallId
    })
    this.context.commit('setGithubInstalls', [
      ...this.githubInstalls,
      githubInstall
    ])
    return githubInstall
  }

  @Action({ rawError: true })
  async githubInstallDelete ({ organizationId, githubInstallId }: { organizationId: string, githubInstallId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await GithubService.githubInstallDelete(authorization, organizationId, githubInstallId)
    this.context.commit('setGithubInstalls', this.githubInstalls.filter(o => o.id !== githubInstallId))
  }

  @Action({ rawError: true })
  async gitlabAuthorizationsList (organizationId: string) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { gitlabAuthorizations } = await GitlabService.gitlabAuthorizationsList(authorization, organizationId)
    this.context.commit('setGitlabAuthorizations', gitlabAuthorizations)
    return gitlabAuthorizations
  }

  @Action({ rawError: true })
  async gitlabAuthorizationCreate ({ organizationId, code }: { organizationId: string, code: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { gitlabAuthorization } = await GitlabService.gitlabAuthorizationCreate(authorization, organizationId, { code })
    this.context.commit('setGitlabAuthorizations', [
      ...this.gitlabAuthorizations,
      gitlabAuthorization
    ])
    return gitlabAuthorization
  }

  @Action({ rawError: true })
  async gitlabAuthorizationAvailableGroups ({ organizationId, gitlabAuthorizationId }: { organizationId: string, gitlabAuthorizationId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { groups } = await GitlabService.gitlabAuthorizationAvailableGroups(authorization, organizationId, gitlabAuthorizationId)
    return groups
  }

  @Action({ rawError: true })
  async gitlabAuthorizationUpdate ({ organizationId, gitlabAuthorizationId, update }: { organizationId: string, gitlabAuthorizationId: string, update: GitlabAuthorizationPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { gitlabAuthorization } = await GitlabService.gitlabAuthorizationUpdate(authorization, organizationId, gitlabAuthorizationId, update)
    this.context.commit('setGitlabAuthorizations', this.gitlabAuthorizations.map(o => o.id === gitlabAuthorizationId ? gitlabAuthorization : o))
    return gitlabAuthorization
  }

  @Action({ rawError: true })
  async gitlabAuthorizationDelete ({ organizationId, gitlabAuthorizationId }: { organizationId: string, gitlabAuthorizationId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await GitlabService.gitlabAuthorizationDelete(authorization, organizationId, gitlabAuthorizationId)
    this.context.commit('setGitlabAuthorizations', this.gitlabAuthorizations.filter(o => o.id !== gitlabAuthorizationId))
  }
}
