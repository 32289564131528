import { RouteConfig } from 'vue-router'

import AppbarLogo from '@/components/appbar/logo.vue'
import UserAppbarAvatars from '@/modules/user/components/appbar/avatars.vue'

import OrganizationAppbarList from './components/appbar/organization-list.vue'
import OrganizationUpgrade from './components/appbar/upgrade.vue'

export default [
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "organization-manage-overview" */ './views/overview.vue'),
        meta: {
          requiresPermission: 'billing',
          title: 'Manage organization'
        },
        name: 'organization-manage-overview',
        path: 'overview'
      },
      {
        component: () => import(/* webpackChunkName: "organization-manage-diagramming" */ './views/diagramming.vue'),
        meta: {
          requiresPermission: 'admin',
          title: 'Manage organization'
        },
        name: 'organization-manage-diagramming',
        path: 'diagramming'
      },
      {
        component: () => import(/* webpackChunkName: "organization-manage-sharing" */ './views/sharing.vue'),
        meta: {
          requiresPermission: 'admin',
          title: 'Manage organization'
        },
        name: 'organization-manage-sharing',
        path: 'sharing'
      },
      {
        component: () => import(/* webpackChunkName: "organization-manage-members" */ './views/members.vue'),
        meta: {
          requiresPermission: 'billing',
          title: 'Manage organization'
        },
        name: 'organization-manage-members',
        path: 'members'
      },
      {
        component: () => import(/* webpackChunkName: "organization-manage-billing" */ './views/billing.vue'),
        meta: {
          requiresPermission: 'billing',
          title: 'Manage organization'
        },
        name: 'organization-manage-billing',
        path: 'billing'
      },
      {
        component: () => import(/* webpackChunkName: "organization-manage-teams" */ './views/teams.vue'),
        meta: {
          requiresPermission: 'admin',
          title: 'Manage organization'
        },
        name: 'organization-manage-teams',
        path: 'teams'
      },
      {
        component: () => import(/* webpackChunkName: "organization-manage-integrations" */ './views/integrations.vue'),
        meta: {
          requiresPermission: 'admin',
          title: 'Manage organization'
        },
        name: 'organization-manage-integrations',
        path: 'integrations'
      },
      {
        component: () => import(/* webpackChunkName: "organization-manage-ap-keys" */ './views/api-keys.vue'),
        meta: {
          requiresPermission: 'admin',
          title: 'Manage organization'
        },
        name: 'organization-manage-api-keys',
        path: 'api-keys'
      }
    ],
    components: {
      'appbar-left-1': OrganizationAppbarList,
      'appbar-right-1': UserAppbarAvatars,
      'appbar-right-2': OrganizationUpgrade,
      default: () => import(/* webpackChunkName: "organization-manage" */ './views/manage.vue')
    },
    meta: {
      background: '#323435',
      hasAppbar: true,
      requiresAuth: true
    },
    name: 'organization-manage',
    path: '/organizations/:organizationId/manage',
    redirect: { name: 'organization-manage-overview' }
  },
  {
    components: {
      'appbar-left-1': AppbarLogo,
      default: () => import(/* webpackChunkName: "organizations" */ './views/index.vue')
    },
    meta: {
      background: '#323435',
      hasAppbar: true,
      requiresAuth: true
    },
    name: 'organizations',
    path: '/organizations'
  },
  {
    component: () => import(/* webpackChunkName: "azure-devops-authorization" */ './views/azure-devops-authorization.vue'),
    meta: {
      background: 'lights',
      title: 'Azure DevOps authorization'
    },
    name: 'azure-devops-authorization',
    path: '/azure-devops/authorization'
  },
  {
    component: () => import(/* webpackChunkName: "bitbucket-authorization" */ './views/bitbucket-authorization.vue'),
    meta: {
      background: 'lights',
      title: 'Bitbucket authorization'
    },
    name: 'bitbucket-authorization',
    path: '/bitbucket/authorization'
  },
  {
    component: () => import(/* webpackChunkName: "github-auth" */ './views/github-install.vue'),
    meta: {
      background: 'lights',
      title: 'GitHub install'
    },
    name: 'github-install',
    path: '/github/install'
  },
  {
    component: () => import(/* webpackChunkName: "gitlab-authorization" */ './views/gitlab-authorization.vue'),
    meta: {
      background: 'lights',
      title: 'GitLab authorization'
    },
    name: 'gitlab-authorization',
    path: '/gitlab/authorization'
  }
] as RouteConfig[]
