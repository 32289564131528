import { IconDefinition, Library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueConstructor from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify/lib'
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'

import { UserModule } from '../user/store'
import customIcons from './helpers/custom-icons'
import * as filters from './helpers/filters'
import icons from './helpers/icons'
import routes from './routes'
import { OrganizationModule } from './store'

export const install = ({ vuetify, store, library, router, Vue }: { vuetify: Vuetify, store: Store<any>, router: VueRouter, library: Library, Vue: typeof VueConstructor }) => {
  const iconDefinitions = [...customIcons, ...icons]
  iconDefinitions.forEach(definition => {
    library.add(definition as IconDefinition)

    vuetify.framework.icons.values[`${definition.prefix}-${definition.iconName}`] = {
      component: FontAwesomeIcon,
      props: {
        icon: [definition.prefix, definition.iconName]
      }
    }
  })

  store.registerModule(OrganizationModule.namespace, OrganizationModule)

  Vue.filter('endsIn', filters.endsIn)
  Vue.filter('plural', filters.plural)

  if (!env.IS_SHARE_LINK) {
    router.addRoutes(routes)
  }

  router.afterEach(to => {
    if (to.params.organizationId) {
      const userModule = getModule(UserModule, store)

      if (userModule.user?.defaultOrganizationId !== to.params.organizationId) {
        userModule.userUpdate({
          defaultOrganizationId: to.params.organizationId
        })
      }
    }
  })
}
