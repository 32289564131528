import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

const prefix: any = 'custom'

const duotoneCircleQuestion: IconDefinition = {
  icon: [512, 512, [], 'f059', ['M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z', 'M222.6 128c-23.7 0-44.8 14.9-52.8 37.3l-.4 1.2c-4.4 12.5 2.1 26.2 14.6 30.6s26.2-2.1 30.6-14.6l.4-1.2c1.1-3.2 4.2-5.3 7.5-5.3h58.3c8.4 0 15.1 6.8 15.1 15.1c0 5.4-2.9 10.4-7.6 13.1l-44.3 25.4c-7.5 4.3-12.1 12.2-12.1 20.8V264c0 13.3 10.7 24 24 24c13.1 0 23.8-10.5 24-23.6l32.3-18.5c19.6-11.3 31.7-32.2 31.7-54.8c0-34.9-28.3-63.1-63.1-63.1H222.6zM256 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z']],
  iconName: 'duotone-circle-question' as any,
  prefix
}

const duotoneImage: IconDefinition = {
  icon: [512, 512, [], 'f03e', ['M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z', 'M323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176z']],
  iconName: 'duotone-image' as any,
  prefix
}

const duotoneMessageLines: IconDefinition = {
  icon: [512, 512, [], 'f4a6', ['M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm152 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z', 'M128 168c0-13.3 10.7-24 24-24H360c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H264c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24z']],
  iconName: 'duotone-message-lines' as any,
  prefix
}

const duotoneSparkles: IconDefinition = {
  icon: [512, 512, [], 'f890', ['M327.5 85.2L384 64 405.2 7.5C406.9 3 411.2 0 416 0s9.1 3 10.8 7.5L448 64l56.5 21.2c4.5 1.7 7.5 6 7.5 10.8s-3 9.1-7.5 10.8L448 128l-21.2 56.5c-1.7 4.5-6 7.5-10.8 7.5s-9.1-3-10.8-7.5L384 128l-56.5-21.2c-4.5-1.7-7.5-6-7.5-10.8s3-9.1 7.5-10.8zm0 320L384 384l21.2-56.5c1.7-4.5 6-7.5 10.8-7.5s9.1 3 10.8 7.5L448 384l56.5 21.2c4.5 1.7 7.5 6 7.5 10.8s-3 9.1-7.5 10.8L448 448l-21.2 56.5c-1.7 4.5-6 7.5-10.8 7.5s-9.1-3-10.8-7.5L384 448l-56.5-21.2c-4.5-1.7-7.5-6-7.5-10.8s3-9.1 7.5-10.8z', 'M205.1 73.3c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3L123.4 187.4 9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5l114.1 52.7L176 435.8c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l52.7-114.1 114.1-52.7c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5L257.8 187.4 205.1 73.3z']],
  iconName: 'duotone-sparkles' as any,
  prefix
}

const duotoneTemperatureHalf: IconDefinition = {
  icon: [320, 512, [], 'f2c9', ['M160 64c-26.5 0-48 21.5-48 48V276.5c0 17.3-7.1 31.9-15.3 42.5C86.2 332.6 80 349.5 80 368c0 44.2 35.8 80 80 80s80-35.8 80-80c0-18.5-6.2-35.4-16.7-48.9c-8.2-10.6-15.3-25.2-15.3-42.5V112c0-26.5-21.5-48-48-48zM48 112C48 50.2 98.1 0 160 0s112 50.1 112 112V276.5c0 .1 .1 .3 .2 .6c.2 .6 .8 1.6 1.7 2.8c18.9 24.4 30.1 55 30.1 88.1c0 79.5-64.5 144-144 144S16 447.5 16 368c0-33.2 11.2-63.8 30.1-88.1c.9-1.2 1.5-2.2 1.7-2.8c.1-.3 .2-.5 .2-.6V112z', 'M176 208c0-8.8-7.2-16-16-16s-16 7.2-16 16V322.7c-18.6 6.6-32 24.4-32 45.3c0 26.5 21.5 48 48 48s48-21.5 48-48c0-20.9-13.4-38.7-32-45.3V208z']],
  iconName: 'duotone-temperature-half' as any,
  prefix
}

const duotoneTriangleExclamation: IconDefinition = {
  icon: [512, 512, [], 'f071', ['M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z', 'M280 184c0-13.3-10.7-24-24-24s-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184zM256 416a32 32 0 1 0 0-64 32 32 0 1 0 0 64z']],
  iconName: 'duotone-triangle-exclamation' as any,
  prefix
}

const duotoneTrophy: IconDefinition = {
  icon: [576, 512, [], 'f091', ['M441.8 112c1.8-15.1 3.3-31.1 4.3-48H552c13.3 0 24 10.7 24 24c0 134.5-70.4 207.7-140.5 246.1c-34.5 18.9-68.8 29.3-94.3 35c-12.2 2.7-22.5 4.4-30 5.4c18.1-9.9 44.1-29.5 68.6-67.3c10.6-4.2 21.6-9.2 32.6-15.2c53.7-29.4 107.1-82 114.6-179.9H441.8zM196.1 307.2c24.5 37.8 50.6 57.4 68.6 67.3c-7.5-1-17.8-2.7-30-5.4c-25.5-5.7-59.8-16.1-94.3-35C70.4 295.7 0 222.5 0 88C0 74.7 10.7 64 24 64H129.9c1 16.9 2.5 32.9 4.3 48H48.9c7.5 97.9 60.9 150.6 114.6 179.9c11 6 22 11 32.6 15.2z', 'M256 395.5c0-16.3-8.6-31.2-20.8-42C192.2 315.3 137.3 231 129 48c-1.2-26.5 20.4-48 47-48H400c26.5 0 48.1 21.6 47 48c-8.2 183-63.2 267.2-106.2 305.4c-12.2 10.8-20.8 25.7-20.8 42V400c0 26.5 21.5 48 48 48h16c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32s14.3-32 32-32h16c26.5 0 48-21.5 48-48v-4.5z']],
  iconName: 'duotone-trophy' as any,
  prefix
}

export default [
  duotoneCircleQuestion,
  duotoneImage,
  duotoneMessageLines,
  duotoneSparkles,
  duotoneTemperatureHalf,
  duotoneTriangleExclamation,
  duotoneTrophy
]
