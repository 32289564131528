import { Module, VuexModule } from 'vuex-module-decorators'

import { ButtonTab } from './components/button-tabs.vue'

export interface IOverviewModule {}

const name = 'overview'

@Module({
  name,
  namespaced: true
})
export class OverviewModule extends VuexModule {
  static namespace = name

  get timelineTabs (): ButtonTab[] {
    return [
      { id: 'today', name: 'Today' },
      { id: 'yesterday', name: 'Yesterday' },
      { id: 'week', name: 'This week' },
      { id: 'month', name: 'This month' },
      { id: 'all', name: 'All time' }
    ]
  }
}
